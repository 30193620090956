<template>
  <viewer :images="imageList" :options="options" class="images clearfix">
    <img v-for="src in imageList" :key="src" :src="src" />
  </viewer>
</template>

<script>
import { propertyService } from "@/api/utils/request";

export default {
  name: "gallery",
  props: {
    value: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      gid: "",
      imageList: [],
      options: {
      },
    };
  },
  mounted() {
     console.log("gallery init");
     
  },
  //设置默认值
  watch: {
    value: {
      deep: true,
      handler(v) {
        if(v){
        console.log("异步接收值", v);
        let ids = v.split(",");
        this.showImgs(ids);
        }
      },
    },
  },
  methods: {
    //显示图片
    showImgs(ids) {
      let that = this;
      that.imageList = [];
      ids.forEach(function (id) {
        console.log(id);
        return propertyService({
          url: "/api/file-management/download-info",
          method: "get",
          params: { id: id },
          needToken: true,
        }).then((res) => {
          if (res.success == true) {
            that.imageList.push(res.data.downloadUrl);
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.images img {
  width: calc(20% - 10px);
  min-width: 150px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
</style>