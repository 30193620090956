<template>
  <div>
    <div>
      <gallery id="g1" :value="imageList1"></gallery>
    </div>
    <div>
      <gallery id="g2" :value="imageList2"></gallery>
    </div>
  </div>
</template>

<script>
import gallery from "@/components/gallery/gallery.vue";

export default {
  components: {
    gallery,
  },
  data() {
    return {
      imageList1: '',
      imageList2: '',
    };
  },

  mounted() {
    this.imageList1 ="3a019c95-d32d-22a0-0400-1f3aced4f7ae,3a019cba-d150-aee3-75d7-f18f0ec5683d,3a019cbd-4868-3565-5d69-e15ebea4ee93,3a019cdc-26ed-d96b-81ab-3f9ed8117825";
    this.imageList2 ="3a019d15-00cc-1731-1106-48ce42a7fc59,3a019d18-b746-2836-1db3-3223a7bc2ace,3a019d6c-6ad8-d602-08e8-603a6d99c9ba";
  },
};
</script>


